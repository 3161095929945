<script setup>
import { computed, onMounted, ref } from "vue";
import { actions } from "@/utils/constants";
import { useDownloadFile, useRouter } from "@/utils/vue_helpers";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import ViewItemSectionTitle from "../utils/ViewItemSectionTitle.vue";
import Table from "../utils/Table.vue";
import { getFormattedBillDate, getFormattedMoney } from "@/utils/functions";
import BackButton from "../utils/BackButton.vue";
import ActionButton from "../utils/ActionButton.vue";
import ViewPdf from './ViewPdf.vue'
import apiCall from "@/utils/api.js";

import { getWorkflowApprovers, downloadDocument } from "./buildings_business_logic";

const { downloadFile } = useDownloadFile();

const workflow_approvers_array = ref([])
const printApplicationDetailsPdfDialogOpen = ref(false)

const viewCirculationCertDialogOpen = ref(false)
const viewPlupaPdfDialogOpen = ref(false)

resetSnackbarOnMount();

const props = defineProps({
  building_application: {
    type: Object,
    default: null,
  },
  showGoBack: {
    type: Boolean,
    default: false,
  },
});

const houseNotEmpty = computed(() => {
  return props.building_application !== null;
});


function onDownloadBillClicked(bill_object) {
  downloadFile({ billId: props.building_application.building_application_bill.bills_id, billNumber: props.building_application.building_application_bill.bills.bill_number });

}

function printApplicationDetails() {
  printApplicationDetailsPdfDialogOpen.value = true

}

function downloadApplicationDetail(){
  downloadBuildingSummaryDocument()

}

function onDownloadReceiptClicked(recept_object) {
  // console.log("onDownloadReceiptClicked")
  // console.log(recept_object)
  // console.log(props.building_application)
  // console.log(props.building_application.building_application_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number)

  if (props.building_application.building_application_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number) {
    const recept_num = props.building_application.building_application_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number
    downloadFile({ recept_num });

  }
  else {
    snackbarData.text = "No Receipt Available, Please Pay for this Bill.";
    snackbarData.color = "success";
    snackbarData.open = true;
  }


}

function returnPrintDetailsUrl() {
  return `${process.env.VUE_APP_API_URL}/api/client/generate-building-application-pdf?application_id=${props.building_application.id}`

}

function returnCirclCertUrl(){
  return `${process.env.VUE_APP_API_URL}/api/client/generate-building-circulation-pdf?application_id=${props.building_application.id}`

}

function returnPlupaUrl(){
  return `${process.env.VUE_APP_API_URL}/api/client/generate-plupa-pdf?application_id=${props.building_application.id}?application_status=${props.building_application.building.status.status}`

}

function showSelectedDialog(item){

  console.log("props.building_application 12345")
  console.log(props.building_application)
  console.log(props.building_application.building_application_bill.bills.bills_invoices[0].invoice_receipt)

  if (props.building_application.building_application_bill.bills.bills_invoices[0].invoice_receipt == null) {
    snackbarData.text = "Please pay for this application before accessing the required documents.";
    snackbarData.color = "error";
    snackbarData.timeout = 4000;
    snackbarData.open = true;
    return
    
  }

  if(item.doc_name == "Plupa"){
    viewPlupaPdfDialogOpen.value = true

    
  }
  else if(item.doc_name == "Circulation Certificate"){
    viewCirculationCertDialogOpen.value = true

  }
  
}

async function downloadBuildingSummaryDocument() {
    apiCall({
        url: "/api/client/getBuildingSummaryDoc?building_application_id=" + props.building_application.id,
        method: "GET",
    }, {
        responseType: 'blob', // Important to handle the file download
    })
        .then((resp) => {
            console.log("Inside downBuild")
            console.log(typeof resp)
            
            downloadPdf(resp, 'Building Summary Document')

        })
        .catch((error) => {
            alert("Error Occured " + error);

        });

}

function downloadPdf(blob, filename) {
    console.log("blob_blob_blob1111111111111")
    console.log(typeof blob)

    console.log("blob_blob_blob222222222222222")
    console.log(blob)


    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // The same filename as set in Laravel
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

async function onDownloadAttachedDocument(document_item) {
  console.log("onDownloadAttachedDocument")
  console.log(document_item)

  const downloadDocument_var = await downloadDocument(document_item.doc_path, document_item.document_name)

}

const buildingDetails = computed(() => {
  let buildingDetails = {};
  if (houseNotEmpty.value) {
    buildingDetails = getBuildingDetails(props.building_application);
  }
  return buildingDetails;
});


function getBuildingDetails(building_application) {
  const buildingDetails = {
    section1: {},
    section2: {},
  };
  buildingDetails.section1["Parcel Number"] = building_application.building.parcel_no;
  buildingDetails.section1["Subcounty"] = building_application.building.sub_counties.sub_county_name;
  buildingDetails.section1["Ward"] = building_application.building.ward.ward_name;
  buildingDetails.section1["Town"] = building_application.building.towns.town_name;
  buildingDetails.section1["Latitude"] = building_application.building.latitude;
  buildingDetails.section1["Longitude"] = building_application.building.longitude;

  buildingDetails.section2["Cost"] = building_application.building.cost;
  buildingDetails.section2["Unit of Measure"] = 'Sq Meters'
  buildingDetails.section2["Plinth Area"] = building_application.building.plinth_area
  buildingDetails.section2["Development Type"] = building_application.building.building_development_type.type_of_development
  buildingDetails.section2["Number of Floors"] = building_application.building.number_of_floors
  buildingDetails.section2["Approval Number"] = building_application.building.approval_number
  buildingDetails.section2["Physical Location/Lanmark"] = building_application.building.physical_address

  return buildingDetails;
}

function checkIfBillReceiptPaid(item) {
  if (item == 'Bill') {

    if (bills_and_receipts_object.value?.bill_workflow?.status_name == 'Paid') {
      return true

    }
    return false


  }
  else if (item == 'Receipt') {

    if (bills_and_receipts_object.value?.bills_invoices?.[0].invoice_receipt?.receipt?.amount) {
      return true

    }
    return false

  }

}

function returnBillReceiptString(item) {
  if (item == 'Bill') {

    if (bills_and_receipts_object.value?.bill_workflow?.status_name == 'Paid') {
      return 'Paid'

    }
    return 'Unpaid'


  }
  else if (item == 'Receipt') {

    if (bills_and_receipts_object.value?.bills_invoices?.[0].invoice_receipt?.receipt?.amount) {
      return 'Paid'

    }
    return 'Unpaid'

  }

}

function closeViewPdfDialog() {
  printApplicationDetailsPdfDialogOpen.value = false
  viewCirculationCertDialogOpen.value = false
  viewPlupaPdfDialogOpen.value = false

}

const building_architects = computed(() => {
  const building_architects = props.building_application.building.building_architect
  return building_architects

})

const building_architect = computed(() => {
  const building_architect = props.building_application.building.building_architect
  return building_architect

});

const building_owner = computed(() => {
  const building_owner = props.building_application.building.building_owner
  return building_owner

});

const building_linked_properties = computed(() => {
  const building_linked_properties = props.building_application.building.building_linked_properties
  return building_linked_properties

});

const building_attached_documents = computed(() => {
  const building_attached_documents = props.building_application.documents
  return building_attached_documents

});

const property_owners = computed(() => {
  const property_owners = props.building_application.building.building_linked_properties ? props.building_application.building.building_linked_properties[0].properties.active_property_owners : []
  return property_owners

});

const plupa_and_circulation_docs = computed(() => {
  const plupa_and_circ_docs = [{ 'id': '1', 'doc_name': 'Plupa' }, { 'id': '2', 'doc_name': 'Circulation Certificate' }]
  return plupa_and_circ_docs

});

const bills_and_receipts_array = computed(() => {
  const bills_and_receipts_array = [{ 'id': '1', 'type': 'Bill', 'number': '', 'date': '', 'amount': '', 'status': '' }, { 'id': '2', 'type': 'Receipt', 'number': '', 'date': '', 'amount': '', 'status': '' }]
  return bills_and_receipts_array

});

const bills_and_receipts_object = computed(() => {
  const bills_and_receipts_object = props.building_application.building_application_bill?.bills
  return bills_and_receipts_object

});



const smallScreenHouseDetails = computed(() => {
  const combinedHouseDetails = [
    buildingDetails.value.section1,
    buildingDetails.value.section2,
  ].reduce((previousVal, currentVal) => {
    Object.entries(currentVal).forEach(
      ([key, value]) => (previousVal[key] = value)
    );
    return previousVal;
  }, {});
  const tableHeaders = Object.keys(combinedHouseDetails).map(
    (houseDetailKey) => ({
      text: houseDetailKey,
      value: houseDetailKey,
    })
  );

  return {
    houseDetailsEnclosedInArray: [combinedHouseDetails],
    tableHeaders,
  };
});

onMounted(async () => {
  console.log("onMounted viewBuilding")
  console.log(props.building_application)

  console.log("bills_and_receipts_object")
  console.log(bills_and_receipts_object)

  const workflow_approvers = await getWorkflowApprovers("Building Application", props.building_application.id)

  console.log("workflow_approvers")
  console.log(workflow_approvers)

  if (workflow_approvers.length != undefined) {
    workflow_approvers_array.value = workflow_approvers

  }

  console.log("workflow_approvers_array")
  console.log(workflow_approvers_array)




})
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="1">
        <BackButton @click="$emit('goBackClicked')">
          Back To Houses
        </BackButton>
      </v-col>
      <v-col cols="12" md="10">
        <div class="text-h6 text-center font-weight-bold primary--text">
          Building Details
        </div>
      </v-col>
      <v-spacer />
    </v-row>

    <div class="d-flex justify-end mb-4">
      <v-btn class="text-none" color="success" depressed @click="printApplicationDetails">
        <span>Print Application Details</span>
        <v-icon right>mdi-printer</v-icon>
      </v-btn>
      <v-btn class="ml-2 text-none" color="warning" depressed @click="downloadApplicationDetail">
        <span>Download Application Doc</span>
        <v-icon right>mdi-printer</v-icon>
      </v-btn>
    </div>




    <v-row justify-md="center">
      <v-col v-if="$mobileBreakpoint" cols="12">
        <Table :headers="smallScreenHouseDetails.tableHeaders"
          :items="smallScreenHouseDetails.houseDetailsEnclosedInArray">
        </Table>
      </v-col>

      <template v-else>
        <v-col v-for="(houseDetailSection, sectionName, index) in buildingDetails" :key="sectionName" cols="12" md="6">

          <v-row v-if="index == 0">
            <v-col cols="auto">
              <ViewItemSectionTitle>Building Plan Details</ViewItemSectionTitle>
            </v-col>
          </v-row>

          <v-simple-table dense class="building_application-details-table">
            <tbody>
              <tr v-for="(houseAttribute, attributeTitle) in houseDetailSection" :key="attributeTitle">
                <td class="font-weight-bold">
                  {{ attributeTitle }}
                </td>
                <td>
                  <template v-if="attributeTitle !== 'Occupied'">
                    {{ houseAttribute }}</template>
                  <template v-else>
                    <v-icon v-if="houseAttribute === true" color="success">mdi-check-circle</v-icon>
                    <v-icon v-else color="error">mdi-close</v-icon>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Architect Details</ViewItemSectionTitle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'Architect Name',
            value: 'architect_name',
            fn: (building_architect) => building_architect.architects ? building_architect.architects.first_name + ' ' + building_architect.architects.last_name : ''

          },
          {
            text: 'ID No.',
            value: 'id_number',
            fn: (building_architect) => building_architect.architects ? building_architect.architects.id_number : ''

          },
          {
            text: 'Phone No.',
            value: 'phone_number',
            fn: (building_architect) => building_architect.architects ? building_architect.architects.phone_number : ''

          },
          {
            text: 'Email',
            value: 'email',
            fn: (building_architect) => building_architect.architects ? building_architect.architects.email_address : ''

          },
          {
            text: 'License Expiry Date',
            value: 'license_expiry',
            fn: (building_architect) => building_architect.architects ? building_architect.architects.license_expiry_date : ''

          },

        ]" dense :items="building_architect" bodyClasses="text-caption">

        </Table>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Client Details</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'Customer Name',
            value: 'customer_name',
            fn: (building_owner) => building_owner.clients ? building_owner.clients.customer_name : ''

          },
          {
            text: 'Designation',
            value: 'designation',
            fn: (building_owner) => building_owner.clients ? building_owner.clients.designation : ''

          },
          {
            text: 'Phone No.',
            value: 'phone_number',
            fn: (building_owner) => building_owner.clients ? building_owner.clients.phone_number1 : ''

          },
          {
            text: 'ID No.',
            value: 'id_no',
            fn: (building_owner) => building_owner.clients ? building_owner.clients.id_number : ''

          },
          {
            text: 'Client Type',
            value: 'client_type',
            fn: (building_owner) => building_owner.clients ? building_owner.clients.client_type : ''

          },

        ]" dense :items="building_owner" bodyClasses="text-caption">

        </Table>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Linked Property Details</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'PRN No.',
            value: 'prn_no',
            fn: (building_linked_properties) => building_linked_properties.properties ? building_linked_properties.properties.prn : ''

          },
          {
            text: 'Owner/Customer',
            value: 'owner',
            fn: (building_linked_properties) => {

              let all_names = '';


              if (building_linked_properties.properties.active_property_owners.length > 0) {

                building_linked_properties.properties.active_property_owners.forEach((item, index) => {
                  if (index == 0) {
                    all_names = all_names + item.owner.customer_name

                  } else {
                    all_names = all_names + ' & ' + item.owner.customer_name

                  }

                })

              }

              return all_names

            }

          },
          {
            text: 'Sub County',
            value: 'sub_county',
            fn: (building_linked_properties) => building_linked_properties.properties.sub_county ? building_linked_properties.properties.sub_county.sub_county_name : ''

          },
          {
            text: 'Property Type.',
            value: 'property_type',
            fn: (building_linked_properties) => ''

          },
          {
            text: 'LR Number',
            value: 'lr_number',
            fn: (building_linked_properties) => building_linked_properties.properties ? building_linked_properties.properties.lr_no : ''

          },

          {
            text: 'Block/Plot Number',
            value: 'block_plot_number',
            fn: (building_linked_properties) => building_linked_properties.properties ? building_linked_properties.properties.block_no : ''

          },

          {
            text: 'Status',
            value: 'status',
            fn: (building_linked_properties) => building_linked_properties.properties ? building_linked_properties.properties.status?.status_name : ''

          },

        ]" dense :items="building_linked_properties" bodyClasses="text-caption">

        </Table>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Attached Documents</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'No.',
            value: 'id',
            fn: (building_attached_documents) => props.building_application.documents.findIndex(x => x.id == building_attached_documents.id) + 1

          },
          {
            text: 'Document Name',
            value: 'document_name',
            fn: (building_attached_documents) => building_attached_documents ? building_attached_documents.document_name : ''

          },
          {
            text: 'Document Type',
            value: 'phone_number',
            fn: (building_attached_documents) => building_attached_documents ? building_attached_documents.document_type : ''

          },
          {
            text: 'Actions',
            value: 'download',
            action: true,
            //fn: (building_application) => building_application.workflow_status.status_name
          },


        ]" dense :items="building_attached_documents" bodyClasses="text-caption">

          <template #item.download="{ item: document_item }">

            <ActionButton :action="actions.downloadReceipt" text="Download Document" inTable
              @click="onDownloadAttachedDocument(document_item)" />
          </template>

          <template #item.view="{ item }">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="button mr-1 primary--text" @click="$emit('viewBuilding', item)" v-bind="attrs"
                    v-on="on">

                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("viewMsg") }}</span>
              </v-tooltip>
            </div>
          </template>

        </Table>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Bills and Receipts</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'No.',
            value: 'id',

          },
          {
            text: 'Type',
            value: 'type',

          },
          {
            text: 'Number',
            value: 'number',
            fn: (bills_and_receipts_array) => {
              if (bills_and_receipts_array.type == 'Bill') {
                return bills_and_receipts_object?.bill_number

              } else if (bills_and_receipts_array.type == 'Receipt') {
                return bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number

              }

              return ''

            }

          },
          {
            text: 'date',
            value: 'Date',
            fn: (bills_and_receipts_array) => {
              if (bills_and_receipts_array.type == 'Bill') {
                return getFormattedBillDate(bills_and_receipts_object?.created_at)

              } else if (bills_and_receipts_array.type == 'Receipt') {
                if (bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.payment_date) {
                  return getFormattedBillDate(bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.payment_date)
                }

              }

              return ''

            }

          },
          {
            text: 'amount',
            value: 'Amount',
            fn: (bills_and_receipts_array) => {
              if (bills_and_receipts_array.type == 'Bill') {
                return bills_and_receipts_object?.amount

              } else if (bills_and_receipts_array.type == 'Receipt') {
                return bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.amount

              }

              return ''

            }

          },
          {
            text: 'status',
            action: true,
            value: 'status',
            // fn: (bills_and_receipts_array) =>{
            //   if(bills_and_receipts_array.type == 'Bill'){
            //     return bills_and_receipts_object?.bill_workflow?.status_name

            //   }else if(bills_and_receipts_array.type == 'Receipt'){

            //     if(bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.amount){
            //       return 'Paid'

            //     }

            //     return 'Unpaid'


            //   }

            //   return ''

            // }

          },

          {
            text: 'Download',
            value: 'download',
            action: true

          },


        ]" dense :items="bills_and_receipts_array" bodyClasses="text-caption">

          <!-- <template #item.view="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon class="button mr-1 primary--text" @click="$emit('viewBuilding', item)" v-bind="attrs"
                        v-on="on">

                        <v-icon small> mdi-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("viewMsg") }}</span>
                  </v-tooltip>
                </div>
          </template> -->

          <template #item.status="{ item: bills_and_receipts_array_item }">
            <span :class="{
              'font-weight-bold': true,
              'green--text': checkIfBillReceiptPaid(bills_and_receipts_array_item.type),
              'red--text': !checkIfBillReceiptPaid(bills_and_receipts_array_item.type),
            }">{{ returnBillReceiptString(bills_and_receipts_array_item.type) }}</span>
          </template>

          <template #item.download="{ item: bills_and_receipts_array_item }">
            <ActionButton v-if="bills_and_receipts_array_item.type == 'Bill'" :action="actions.downloadBill" inTable
              @click="onDownloadBillClicked(bills_and_receipts_array_item)" />
            <ActionButton v-else :action="actions.downloadReceipt" inTable
              @click="onDownloadReceiptClicked(bills_and_receipts_array_item)" />
          </template>

        </Table>

      </v-col>
    </v-row>




    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Plupa and Circulation Certificate Documents</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'No.',
            value: 'id',


          },
          {
            text: 'Document Name',
            value: 'doc_name',

          },
          {
            text: 'Actions',
            value: 'view',
            action: true,
            //fn: (building_application) => building_application.workflow_status.status_name
          },

        ]" dense :items="plupa_and_circulation_docs" bodyClasses="text-caption">

          <template #item.view="{ item }">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="button mr-1 primary--text" @click="showSelectedDialog(item)" v-bind="attrs"
                    v-on="on">

                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("viewMsg") }}</span>
              </v-tooltip>
            </div>
          </template>

        </Table>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Workflow</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'User',
            value: 'user',
          },
          {
            text: 'Role',
            value: 'role',
          },
          {
            text: 'Action',
            value: 'action',
          },
          {
            text: 'Comment',
            value: 'comment',
          },
          {
            text: 'Approval Date',
            value: 'actioned',
          },

        ]" dense :items="workflow_approvers_array" bodyClasses="text-caption">

        </Table>

      </v-col>
    </v-row>






    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Owner(s)</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'Customer Name',
            value: 'customer_name',
            fn: (property_owners) => property_owners.owner ? property_owners.owner.customer_name : ''

          },
          {
            text: 'ID Number',
            value: 'designation',
            fn: (property_owners) => property_owners.owner ? property_owners.owner.id_number : ''

          },
          {
            text: 'Phone No.',
            value: 'phone_number',
            fn: (property_owners) => property_owners.owner ? property_owners.owner.phone_number1 : ''

          },
          {
            text: 'Customer No.',
            value: 'id_no',
            fn: (property_owners) => property_owners.owner ? property_owners.owner.customer_number : ''

          },
          {
            text: 'Email',
            value: 'email',
            fn: (property_owners) => property_owners.owner ? property_owners.owner.email_address : ''

          },

        ]" dense :items="property_owners" bodyClasses="text-caption">
        </Table>

      </v-col>
    </v-row>

    <ViewPdf v-if="printApplicationDetailsPdfDialogOpen" :pdfUrl="returnPrintDetailsUrl()"
      :dialogOpen.sync="printApplicationDetailsPdfDialogOpen" title="Building Application Details"
      printBtnText="Print Application Details" @closeViewPdfDialog="closeViewPdfDialog" />

    <ViewPdf v-if="viewCirculationCertDialogOpen" :pdfUrl="returnCirclCertUrl()"
      :dialogOpen.sync="viewCirculationCertDialogOpen" title="Circulation Certificate"
      printBtnText="Print Circulation Certificate" @closeViewPdfDialog="closeViewPdfDialog" />

    <ViewPdf v-if="viewPlupaPdfDialogOpen" :pdfUrl="returnPlupaUrl()" :dialogOpen.sync="viewPlupaPdfDialogOpen"
      title="PLUPA DC8 Document" printBtnText="Print PLUPA DC8" @closeViewPdfDialog="closeViewPdfDialog" />


  </v-container>
</template>
