<script setup>
import { createBuildingAppData } from "./buildings_business_logic.js";
import ViewBills from "@/components/billing/view_bills/ViewBills.vue";
import ActionButton from "@/components/utils/ActionButton.vue";
import { billStatuses } from "@/components/billing/view_bills/view_bill_business_logic";
import SectionTitle from "@/components/billing/SectionTitle.vue";
import { useRouter } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import { countyIsKirinyaga } from "@/utils/vue_helpers";
import { computed, ref, watchEffect, onMounted } from "vue";
import { actions } from "@/utils/constants";
import { arrayNotEmpty } from "@/utils/functions";
import BackButton from "../utils/BackButton.vue";
import AttachProperty from "./AttachProperty.vue";
import BuildingPlanDetails from "./BuildingPlanDetails.vue";
import AttachArchitectEngineer from "./AttachArchitectEngineer.vue";
import AttachRequiredDocuments from "./AttachRequiredDocuments.vue";
import SubmitBuildingPlan from "./SubmitBuildingPlan.vue";

const { create_building_aplication_data, building_additional_data } = createBuildingAppData()

const { router, route } = useRouter();

const tabs = {
  LINKED_PROPERTIES: 0,
  BUILDING_PLAN_DETAILS: 1,
  ATTACH_CLIENT_ARCHITECT: 2,
  SUPPORTING_DOCUMENTS: 3,
  SUBMIT_BUILDING_PLAN: 4,
};

const props = defineProps({
  draft_item: {
    type: Object,
    default: null,
  },
});

let currentTab = ref(tabs.LINKED_PROPERTIES);

function goToLinkedPropertiesTab(){
  currentTab.value = tabs.LINKED_PROPERTIES;

}

function goToBuildingPlanDetailsTab(){
  currentTab.value = tabs.BUILDING_PLAN_DETAILS;

}

function goToAttachClientTab(){
  currentTab.value = tabs.ATTACH_CLIENT_ARCHITECT;

}

function goToSupportTingDocsTab(){
  currentTab.value = tabs.SUPPORTING_DOCUMENTS;

}

function goToSubmitBuildingPlanTab(){
  currentTab.value = tabs.SUBMIT_BUILDING_PLAN;

}

function myFunction(){
  console.log("My Function 12345")

}

onMounted(() => {
  console.log("My Function 12345123451234512345")
  console.log(props.draft_item)

  if(props.draft_item != null){
    const linked_prop = JSON.parse(props.draft_item.linked_properties)
    const building_plan = JSON.parse(props.draft_item.building_plan_Details)
    const client = JSON.parse(props.draft_item.client)
    const architect = JSON.parse(props.draft_item.architect)
    const structural_eng = JSON.parse(props.draft_item.structural_eng)

    if(linked_prop != null){
      console.log("linked_prop linked_prop linked_prop")
      console.log(linked_prop)
      create_building_aplication_data.properties_array_data = linked_prop
      building_additional_data.sub_county_name = linked_prop[0].sub_county.sub_county_name
      building_additional_data.ward_name = linked_prop[0].ward.ward_name
      building_additional_data.town_name = linked_prop[0].town.town_name

    }

    if(building_plan != null){
      create_building_aplication_data.is_application_draft = true
      create_building_aplication_data.perimeter_wall_available = building_plan.perimeter_wall_available
      create_building_aplication_data.parcel_no = building_plan.parcel_no
      create_building_aplication_data.sub_county_id = building_plan.sub_county_id
      create_building_aplication_data.ward_id = building_plan.ward_id
      create_building_aplication_data.town_id = building_plan.town_id
      create_building_aplication_data.physical_address = building_plan.physical_address
      create_building_aplication_data.geolocation = building_plan.geolocation
      create_building_aplication_data.cost = building_plan.cost
      create_building_aplication_data.unit_of_measure_id = building_plan.unit_of_measure_id
      create_building_aplication_data.plinth_area = building_plan.plinth_area
      create_building_aplication_data.type_of_development_id = building_plan.type_of_development_id
      create_building_aplication_data.number_of_floors = building_plan.number_of_floors
      create_building_aplication_data.approval_number = building_plan.approval_number
      create_building_aplication_data.latitude = building_plan.latitude
      create_building_aplication_data.longitude = building_plan.longitude
      create_building_aplication_data.client_id = building_plan.client_id
      create_building_aplication_data.perimeter_wall_length = building_plan.perimeter_wall_length
      create_building_aplication_data.land_tenure_id = building_plan.land_tenure_id
      create_building_aplication_data.area_of_property = building_plan.area_of_property
      create_building_aplication_data.type_of_application = building_plan.type_of_application
      create_building_aplication_data.sewer = building_plan.sewer
      create_building_aplication_data.plinth_areas_array = building_plan.plinth_areas_array
      create_building_aplication_data.document_ids_list = building_plan.document_ids_list
      create_building_aplication_data.created_by = building_plan.created_by
      create_building_aplication_data.updated_by = building_plan.updated_by


    }

    if(client != null){
      create_building_aplication_data.clients_array_data = client

    }

    if(architect != null){
      console.log("Inside architects_array_data 11111")
      console.log(create_building_aplication_data.architects_array_data)
      if(create_building_aplication_data.architects_array_data.length == 0){
        create_building_aplication_data.architects_array_data = architect

      }

      console.log("Inside architects_array_data 22222")
      console.log(create_building_aplication_data.architects_array_data)

    }

    if(structural_eng != null){
      create_building_aplication_data.structural_eng_array_data = structural_eng

    }

    


  }

});


// watchEffect(() => {
//   // const routeTabQuery = route.value?.query?.tab ?? null;
//   // const routeHasTabQuery = !!routeTabQuery;
//   // if (routeHasTabQuery) {
//   //   const shouldGoToUnpaid = routeTabQuery === "step_1";
//   //   const shouldGoToPaid = routeTabQuery === "step_2";
//   //   if (shouldGoToUnpaid) {
//   //     currentTab.value = tabs.UNPAID_BILLS;
//   //   } else if (shouldGoToPaid) {
//   //     currentTab.value = tabs.PAID_BILLS;
//   //   }
//   // }
// });


// function getBillIdsAsCommaSeparatedString(bills) {
//   let billIdsString = "";
//   bills.forEach((bill, index) => {
//     billIdsString += bill.id;
//     const notLastItem = index !== bills.length - 1;
//     if (notLastItem) {
//       billIdsString += ",";
//     }
//   });
//   return billIdsString;
// }

// async function onPayBillClicked(bill) {
//   const paymentRoute = routes.payment.withQuery({
//     billNumbersToPayAsString: bill.bill_number,
//     directPayment: true,
//     billsToPay: [bill],
//   });
//   router.value.push(paymentRoute);
// }

const tabText = computed(() => {
  if (countyIsKirinyaga.value) {
    return {
      step_1: "Linked Properties",
      step_2: "Building Plan Details",
      step_3: "Client/Architect Details",
      step_4: "Supporting Documents",
      step_5: "Submit Building Plan Application",

    };
  }
  return {
    step_1: "Linked Properties",
    step_2: "Building Plan Details",
    step_3: "Client/Architect Details",
    step_4: "Supporting Documents",
    step_5: "Submit Building Plan Application",
  };
});

const billsSelectedForPayment = ref([]);

// function billIsSelectedForPayment(bill) {
//   const selectedBill = billsSelectedForPayment.value.find(
//     (billToPay) => billToPay.bill_number === bill.bill_number
//   );
//   const selectedBillNotEmpty = selectedBill !== undefined;
//   return selectedBillNotEmpty;
// }

// const atLeastOneBillMultiSelected = computed(() => {
//   return arrayNotEmpty(billsSelectedForPayment.value);
// });

// function onPayForMultipleBillsClicked() {
//   const billNumbersAsCommaSeparatedString = billsSelectedForPayment.value
//     .map((bill) => bill.bill_number)
//     .join(",");
//   const paymentRoute = routes.payment.withQuery({
//     billNumbersToPayAsString: billNumbersAsCommaSeparatedString,
//     directPayment: true,
//     billsToPay: billsSelectedForPayment.value,
//   });
//   router.value.push(paymentRoute);
// }

// function comparatorFn(a, b) {
//   return a.bill_number === b.bill_number;
// }
</script>

<template>
  <div>

    <v-row>
      <v-col cols="12" md="1">
          <BackButton @click="$emit('goBackClicked')">
            Back To Buildings
          </BackButton>
      </v-col>

      <!-- <v-col>
        <SectionTitle title="Bills And Receipts" />
      </v-col> -->
      
    </v-row>

    <v-col>
        <SectionTitle title="Create Building Application" />
    </v-col>

    <v-tabs
      v-model="currentTab"
      :class="{
        'pt-4 pt-md-0 justify-center justify-md-start': true,
        'd-flex': $mobileBreakpoint,
      }"
    >
      <v-tabs-slider color="secondary" />

      <v-tab @click.stop.prevent="myFunction" v-for="text in tabText" :key="text" class="text-none">
        {{ text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab" touchless class="pt-4 pt-md-0">
      <v-tab-item>
          <AttachProperty @goToPreviousStep="$emit('goBackClicked')" @goToNextStep="goToBuildingPlanDetailsTab()"/>
      </v-tab-item>

      <v-tab-item>
          <BuildingPlanDetails @goToPreviousStep="goToLinkedPropertiesTab" @goToNextStep="goToAttachClientTab()"/>
      </v-tab-item>

      <v-tab-item>
          <AttachArchitectEngineer @goToPreviousStep="goToBuildingPlanDetailsTab" @goToNextStep="goToSupportTingDocsTab()"/>
      </v-tab-item>

      <v-tab-item>
          <AttachRequiredDocuments @goToPreviousStep="goToAttachClientTab" @goToNextStep="goToSubmitBuildingPlanTab()"/>
      </v-tab-item>

      <v-tab-item>
          <SubmitBuildingPlan @goToPreviousStep="goToSupportTingDocsTab"/>
      </v-tab-item>

      

    </v-tabs-items>

  </div>
</template>
