import { ref, reactive, watch, computed } from "vue";
import apiCall from "@/utils/api.js";
import { usePaginatedDataWithDebouncedSearch, usePaginatedDataWithDebouncedSearchDrafts, usePaginatedDataWithDebouncedSearchClient, usePaginatedDataWithDebouncedSearchArchitect, usePaginatedDataWithDebouncedSearchStrucEng } from "@/utils/vue_helpers";
import { useMutation } from "@tanstack/vue-query";

export const my_documents = ref([])
export const loadingBuildingPlan = ref(false)

export const generate_bill_data = reactive({
  client_id: null,
  unit_of_measure_id: null,
  plinth_area: null,
  plinth_areas_array: [],
  number_of_floors: null,
  development_type_id: null,
  perimeter_wall_length: null,
  type_of_application: null

})

//architectStrucEngApplications

const building_additional_data = reactive({
  draft_id: null,
  sub_county_name: null,
  ward_name: null,
  town_name: null,
  unit_of_measure: null,
  type_of_development_name: null,
  property_ownership: null

})

const attached_documents = [
  "Developer ID Copy",
  "Ownership Documents",
  "Indemnity Form",
  "Architect Practicing License",
  "Floor Plan Details",
  "Elevation Details",
  "Cross Sectional Details",
  "Roof Plan Details",
  "Structural Eng Practising License(Optional)",
  "Search Certificate(Optional)",
  "Registry Index Map(Optional)",
  "Structural Plans(Optional)",
  "Structural Calculations(Optional)",
  "Geotechnical Report(Optional)",
  "Change of User Document(Optional)",
  "Lease Agreement(Optional)",
  "Other Details(Optional)"
]

const attached_documents_with_share_certificate = [
  "Developer ID Copy",
  "Ownership Documents",
  "Indemnity Form",
  "Architect Practicing License",
  "Floor Plan Details",
  "Elevation Details",
  "Cross Sectional Details",
  "Roof Plan Details",
  "Sworn Affidavit",
  "Approved Subdivision",
  "Beacon Certificate",
  "Conformity Certificate",
  "Structural Eng Practising License(Optional)",
  "Search Certificate(Optional)",
  "Registry Index Map(Optional)",
  "Structural Plans(Optional)",
  "Structural Calculations(Optional)",
  "Geotechnical Report(Optional)",
  "Change of User Document(Optional)",
  "Lease Agreement(Optional)",
  "Other Details(Optional)"
]


//The object below is where your files will be stored
//Please note, if you have a max of 10 files that need to be sent, include 10 arrays inside that array structure
//For me I may send a maximum of 21 files found in 'attached_documents_with_share_certificate' therefore I included 21 arrays in that array
const attachedDocumentsModel = [
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  []
]


const create_building_aplication_data = reactive({
  from_e_citizen: true,
  is_application_draft: false,
  perimeter_wall_available: false,
  parcel_no: null,
  sub_county_id: null,
  ward_id: null,
  town_id: null,
  physical_address: null,
  geolocation: null,
  cost: null,
  unit_of_measure_id: null,
  plinth_area: null,
  type_of_development_id: null,
  number_of_floors: null,
  approval_number: null,
  latitude: null,
  longitude: null,
  client_id: null,
  perimeter_wall_length: null,
  land_tenure_id: null,
  area_of_property: null,
  type_of_application: null,
  sewer: null,
  plinth_areas_array: [],
  properties_array_data: [],
  clients_array_data: [],
  architects_array_data: [],
  structural_eng_array_data: [],
  document_ids_list:[],
  created_by: null,
  updated_by: null

})

const snackbar_info = reactive({
  snackbar: false,
  snackbar_message: "",
  snackbar_timeout: 3000,
  color: "error"

})

export function snackBarInfoFunction() {

  return {
    snackbar_info

  }
}

export function useSearchForStructuralEngineers() {
  const {
    paginationData: structuralEngData,
    searchModel_strucEngs,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearchStrucEng();

  const url = computed(() => {
    //let url = `/api/client/houses?page=${clientsData.paginationData.currentPage}`;


    let typeOfSearch = 'all'
    // let url = '/api/client/building?type='+typeOfSearch
    let url = '/api/client/queryStructuralEngineers?type=search&search_text=' + debouncedSearchModel.value;

    if (debouncedSearchModelNotEmpty.value) {
      typeOfSearch = 'search'
      url = '/api/client/queryStructuralEngineers?type=specificSearch&search_text=' + debouncedSearchModel.value;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {

      structuralEngData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    structuralEngData,
    searchModel_strucEngs,
  };
}

export function useSearchForArchitects() {
  const {
    paginationData: architectsData,
    searchModel_architects,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearchArchitect();

  const url = computed(() => {
    //let url = `/api/client/houses?page=${clientsData.paginationData.currentPage}`;


    let typeOfSearch = 'all'
    // let url = '/api/client/building?type='+typeOfSearch
    let url = '/api/client/searchArchitect?type=search&search_text=' + debouncedSearchModel.value;

    if (debouncedSearchModelNotEmpty.value) {
      typeOfSearch = 'search'
      url = '/api/client/searchArchitect?type=specificSearch&search_text=' + debouncedSearchModel.value;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {

      architectsData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    architectsData,
    searchModel_architects,
  };
}

export function useSearchForClients() {
  const {
    paginationData: clientsData,
    searchModel_clients,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearchClient();

  const url = computed(() => {
    //let url = `/api/client/houses?page=${clientsData.paginationData.currentPage}`;


    let typeOfSearch = 'all'
    // let url = '/api/client/building?type='+typeOfSearch
    let url = '/api/client?type=search&search=' + debouncedSearchModel.value;

    if (debouncedSearchModelNotEmpty.value) {
      typeOfSearch = 'search'
      url = '/api/client?type=specificSearch&search=' + debouncedSearchModel.value;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {

      clientsData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    clientsData,
    searchModel_clients,
  };
}

export function useViewBuildingsClients() {
  const {
    paginationData: buildingsData,
    searchModel,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearch();

  const url = computed(() => {
    //let url = `/api/client/houses?page=${housesData.paginationData.currentPage}`;
    let typeOfSearch = 'clientsApplications'
    let url = '/api/client/building?type=' + typeOfSearch
    if (debouncedSearchModelNotEmpty.value) {
      typeOfSearch = 'search'
      url = '/api/client/building?type=' + typeOfSearch + `&search_text=${debouncedSearchModel.value}`;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {

      buildingsData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    buildingsData,
    searchModel,
  };
}

export function useViewBuildingDrafts() {
  const {
    paginationData: buildingsData,
    searchModel_draft,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearchDrafts();

  const url = computed(() => {
    //let url = `/api/client/houses?page=${housesData.paginationData.currentPage}`;
    let typeOfSearch = 'all'
    let url = ''

    url = '/api/client/getMyDrafts?type=' + typeOfSearch

    // if(buildingsData.paginationData.currentPage){
    //   url = '/api/client/getMyDrafts?type=' + typeOfSearch + '?page=' + buildingsData.paginationData.currentPage

    // }
    // else{
    //   url = '/api/client/getMyDrafts?type=' + typeOfSearch

    // }

    if (debouncedSearchModelNotEmpty.value) {
      typeOfSearch = 'search'
      url = '/api/client/getMyDrafts?type=' + typeOfSearch + `&search_text=${debouncedSearchModel.value}`;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {

      buildingsData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    buildingsData,
    searchModel_draft,
  };
}

export function useViewBuildings() {
  const {
    paginationData: buildingsData,
    searchModel,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearch();

  const url = computed(() => {
    //let url = `/api/client/houses?page=${housesData.paginationData.currentPage}`;
    let typeOfSearch = 'architectStrucEngApplications'
    let url = '/api/client/building?type=' + typeOfSearch
    if (debouncedSearchModelNotEmpty.value) {
      typeOfSearch = 'search'
      url = '/api/client/building?type=' + typeOfSearch + `&search_text=${debouncedSearchModel.value}`;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {

      buildingsData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    buildingsData,
    searchModel,
  };
}

export function useGenerateHouseBill() {
  async function generateHouseBill(houseData) {
    try {
      const response = await apiCall({
        // url: "/api/client/generatehousebill",
        url: "/api/client/house/generate-bill",
        method: "POST",
        data: houseData,
      });
      //const { bill } = response;
      const bill = response;
      return bill;
    } catch (error) {
      throw error;
    }
  }

  const generateHouseBillMutation = useMutation({
    mutationFn: generateHouseBill,

  });

  return generateHouseBillMutation;
}

export async function getWorkflowApprovers(application_type, application_id) {

  const application = { id: null }

  application.id = application_id
  try {
    const response = await apiCall({
      // url: "/api/client/generatehousebill",
      url: "/api/client/user/workflowapprovers",
      method: "POST",
      data: {
        application_type: application_type,
        application: JSON.stringify(application),
      },
    });

    return response;
  } catch (error) {
    throw error;
  }

}

export async function addPropertyToDraft(){

  try {
    const response = await apiCall({
      url: "/api/client/addProperties",
      method: "POST",
      data: {
        draft_id: building_additional_data.draft_id,
        attachedPropertiesData: create_building_aplication_data.properties_array_data,
      },
    });

    return response;

  } catch (error) {
    throw error;

  }

}


export async function addBuildingDetailsToDraft(){

  try {
    const response = await apiCall({
      url: "/api/client/addBuildingDataToDraft",
      method: "POST",
      data: {
        draft_id: building_additional_data.draft_id,
        attachedBuildingData: create_building_aplication_data,
      },
    });

    return response;

  } catch (error) {
    throw error;

  }

}

export async function addClientFunction(){

  try {
    const response = await apiCall({
      url: "/api/client/addClient",
      method: "POST",
      data: {
        draft_id: building_additional_data.draft_id,
        attachedClientsData: create_building_aplication_data.clients_array_data,
      },
    });

    return response;

  } catch (error) {
    throw error;

  }

}

export async function addArchitectFunction(){

  try {
    const response = await apiCall({
      url: "/api/client/addArchitect",
      method: "POST",
      data: {
        draft_id: building_additional_data.draft_id,
        attachedArchitectsData: create_building_aplication_data.architects_array_data,
      },
    });

    return response;

  } catch (error) {
    throw error;

  }

}

export async function addStrucEngFunction(){

  try {
    const response = await apiCall({
      url: "/api/client/addStructuralEng",
      method: "POST",
      data: {
        draft_id: building_additional_data.draft_id,
        attachedStructuralEngData: create_building_aplication_data.structural_eng_array_data,
      },
    });

    return response;

  } catch (error) {
    throw error;

  }

}


export async function downloadDocument(doc_path, file_name) {

  try {
    const response = await apiCall({
      url: "/api/downloadDocumentOther?doc_path=" + doc_path + "&file_name=" + file_name,
      method: "GET",
      responseType: "blob",

    });

    if (response.status != "error") {

      console.log("response from server")
      console.log(response)

      console.log("response typeof")
      console.log(typeof response)


      const href = URL.createObjectURL(response);


      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', file_name); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);




      // var element = document.createElement("a");

      // element.setAttribute(
      //   "href",
      //   "/img/documents/" + file_name,
      //   +encodeURIComponent("Random Txt")
      // );

      //http://127.0.0.1:8000/img/documents/OQBfSb9ktj93B0SLDfZH.jpg

      // element.setAttribute(
      //   "download",
      //   file_name
    //   );

    //   element.style.display = "none";
    //   document.body.appendChild(element);

    //   element.click();

    //   document.body.removeChild(element);
    // } else {


    //   // if (resp.message.toLowerCase().startsWith("file not found")) {
    //   //   this.snackbar = true;
    //   //   this.snackBartimeout = 4500;
    //   //   this.snackBarText = "File Not Found In Server";
    //   // } else {
    //   //   alert("Error Occured " + resp.message);
    //   // }
    }

    return response;
  } catch (error) {
    throw error;
  }

}

export function setupInformation() {
  const selected_item = 1
  const instruction_list = [
    { text: 'Copy of ownership documents', icon: 'mdi-clock' },
    { text: 'Official Search (not more than 28 days)', icon: 'mdi-account' },
    { text: 'Location Plan', icon: '' },
    { text: 'Architectural Plans', icon: '' },
    { text: 'Mutation/RIM/Plan', icon: '' },
    { text: 'Google Maps location of the site', icon: '' },
    { text: "Owner's signed written consent", icon: '' },
    { text: "Architect's current Practicing Certificate", icon: '' },
    { text: "Engineer's current Practicing Certificate", icon: '' },
  ]

  return {
    selected_item,
    instruction_list,

  }

}
export function attachedDocumentsList() {

  return {
    attached_documents, attached_documents_with_share_certificate, attachedDocumentsModel

  }
}

export function createBuildingAppData() {

  return {
    create_building_aplication_data, building_additional_data

  }
}


export function fetchSewerTypes() {
  async function fetchSewerTypesFunction() {
    try {
      const response = apiCall({
        url: "/api/getAllSewerTypes",
        method: "GET",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const fetchSewerTypesCsvDataMutation = useMutation({
    mutationFn: fetchSewerTypesFunction,

  });

  return fetchSewerTypesCsvDataMutation;

}

export function fetchDevelopmentTypes() {
  async function fetchDevelopmentTypesFunction() {
    try {
      const response = apiCall({
        url: "/api/listDevelopmentTypes",
        method: "GET",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const fetchDevelopmentTypesCsvDataMutation = useMutation({
    mutationFn: fetchDevelopmentTypesFunction,

  });

  return fetchDevelopmentTypesCsvDataMutation;

}


export function fetchPropertyAreaUnits() {
  async function fetchPropertyAreaUnitsFunction() {
    try {
      const response = apiCall({
        url: "/api/listPropertyAreaUnits",
        method: "GET",
      });

      response.then(function (result) {
        // here you can use the result of promise
        create_building_aplication_data.unit_of_measure_id = result.find((x) => x.unit_name == "Sq Meters").id;

      });


      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const fetchPropertyAreaUnitsCsvDataMutation = useMutation({
    mutationFn: fetchPropertyAreaUnitsFunction,

  });

  return fetchPropertyAreaUnitsCsvDataMutation;

}

export function fetchPropertyOwnerships() {
  async function fetchPropertyOwnershipsFunction() {
    try {
      const response = apiCall({
        url: "/api/getAllPropertyOwnerships",
        method: "GET",
      });

      console.log("getAllPropertyOwnerships");
      console.log(response);

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const fetchPropertyOwnershipsCsvDataMutation = useMutation({
    mutationFn: fetchPropertyOwnershipsFunction,

  });

  return fetchPropertyOwnershipsCsvDataMutation;

}

async function generateBuildingBillItems() {
  try {
    const response = await apiCall({
      url: "/api/client/generateBillItems",
      data: generate_bill_data,
      method: "POST",
    });

    return response

  } catch (error) {
    return error;

  }
}

export function generateBuildingBillItemsExportFun(){
  return {
    generateBuildingBillItems

  }

}
