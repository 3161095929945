var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{style:({ position: 'relative' })},[_c(_setup.Table,{attrs:{"headers":[
          {
            text: 'Plinth Area',
            value: 'plinth_area',

          },

          {
            text: 'Physical Location/Landmark',
            value: 'physical_address',

          },

          {
            text: 'Parcel Number',
            value: 'parcel_number',

          },

          {
            text: 'Client Name',
            value: 'client_name',

          },

          {
            text: 'Architect Name',
            value: 'architect_name',
            
          },

          {
            text: 'Created At',
            value: 'created_at',
            fn: (house) => _setup.getFormattedBillDate(house.created_at)

          },


          {
            text: 'Actions',
            value: 'view',
            action: true,
            fn: (house) => house.workflow_status.status_name
          },


          // { text: 'Pay', value: 'is_renewal', action: true },
        ],"items":_vm.buildings,"loading":_vm.loading,"emptyText":"No Buildings Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.view",fn:function({ item }){return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button mr-1 primary--text",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('viewDraftItem', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("viewMsg")))])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button mr-1 primary--text",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('continueApplication', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-page-next")])],1)]}}],null,true)},[_c('span',[_vm._v("Continue with Draft")])])],1)]}},{key:"item.getBill",fn:function({ item: house }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payForHouse', house)}}})]}},{key:"item.getReceipt",fn:function({ item: house }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payForHouse', house)}}})]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }