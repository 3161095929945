import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";
import { getDashboardLinks } from "./dashboard_links";
import { getHelpLinks } from "./help_links";

import { routes as routeObjects } from "./routes";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next({
    name: "AccountDetails",
  });
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next({
    name: "Login",
  });
};

let previousRouteBeforeEnteringHelp = null;

const routes = [
  {
    path: "/",
    beforeEnter: ifNotAuthenticated,
    redirect: { name: "Login" },
  },

  {
    path: "/help",
    name: routeObjects.help.name,
    component: () => import("@/components/help/help.vue"),
    props: (_) => ({
      links: getHelpLinks(),
      prevRoute: previousRouteBeforeEnteringHelp,
    }),
    beforeEnter: (_, from, next) => {
      previousRouteBeforeEnteringHelp = from;
      next();
    },
    children: [
      {
        path: "faq",
        name: routeObjects.faq.name,
        component: () => import("@/components/help/faq/faq.vue"),
      },
    ],
  },

  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/components/auth/AuthContainer.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/components/auth/login/index.vue"),
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "create",
        name: "CreateAccount",
        component: () =>
          import(
            "@/components/auth/create_or_activate_account/CreateOrActivateAccount.vue"
          ),
        beforeEnter: ifNotAuthenticated,
        props: true,
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("@/components/auth/reset_password/index.vue"),
        beforeEnter: ifNotAuthenticated,
      },
    ],
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/components/layout/Dashboard.vue"),
    props: {
      links: getDashboardLinks(),
    },
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "bills",
        name: routeObjects.viewBills.name,
        component: () =>
          import("@/components/billing/view_bills/DashboardViewBills"),
      },
      {
        path: "details",
        name: routeObjects.userAccountDetails.name,
        component: () =>
          import(
            "@/components/user/account/account_information/AccountInformation.vue"
          ),
      },
      {
        path: "businesses/view",
        name: routeObjects.viewBusinesses.name,
        component: () => import("@/components/businesses/ViewBusinesses.vue"),
      },
      {
        path: "buildings/view",
        name: routeObjects.viewBuildings.name,
        component: () => import("@/components/buildings/ViewBuildings.vue"),
      },

      {
        path: "buildings/my-drafts",
        name: routeObjects.viewDrafts.name,
        component: () => import("@/components/buildings/ViewDrafts.vue"),
      },

      {
        path: "buildings/create-building-app",
        name: "createBuildingApp",
        component: () => import("@/components/buildings/PayForHouse.vue"),
        props: true,
      },

      {
        path: "buildings/my-applications",
        name: routeObjects.viewClientBuildings.name,
        component: () => import("@/components/buildings/ViewClientBuildings.vue"),
      },
      
      {
        path: "properties",
        name: routeObjects.viewProperties.name,
        component: () =>
          import("@/components/properties/view_properties/ViewProperties.vue"),
      },
      {
        path: "statements/property-rate",
        name: routeObjects.propertyRateStatement.name,
        component: () =>
          import(
            "@/components/properties/property_statements/property_rate_statement/PropertyRateStatement.vue"
          ),
      },
      {
        path: "profile/view",
        name: routeObjects.userProfileView.name,
        component: () =>
          import(
            "@/components/user/profile/profile_information/ProfileInformation.vue"
          ),
      },
      {
        path: "profile/reset-password",
        name: routeObjects.userLoggedInResetPassword.name,
        component: () =>
          import(
            "@/components/user/profile/change_password/ChangePassword.vue"
          ),
      },
      {
        path: "businesses/view",
        name: "ViewBusinesses",
        component: () => import("@/components/businesses/ViewBusinesses.vue"),
      },
      {
        path: "vehicles/view",
        name: "ViewVehicles",
        component: () => import("@/components/vehicles/ViewVehicles.vue"),
      },
      {
        path: "houses/view",
        name: "ViewHouses",
        component: () => import("@/components/houses/ViewHouses.vue"),
      },
      {
        path: "services",
        name: routeObjects.services.name,
        component: () => import("@/components/services/ServiceContainer.vue"),
        children: [
          {
            path: "default",
            name: routeObjects.defaultService.name,
            props: (route) => ({
              serviceName: route.query["service-name"],
              title: route.query.title,
            }),
            component: () =>
              import(
                "@/components/services/default_service_page/DefaultServicePage.vue"
              ),
          },
          {
            path: "solid-waste",
            name: routeObjects.solidWasteCollection.name,
            component: () =>
              import("@/components/services/actual_services/SolidWaste.vue"),
          },
          {
            path: "noise-pollution",
            name: routeObjects.noisePollutionServices.name,
            component: () =>
              import(
                "@/components/services/actual_services/NoisePollution.vue"
              ),
          },
        ],
      },

      {
        path: "general-billing",
        name: "GeneralBilling",
        component: () =>
          import("@/components/billing/general_billing/GeneralBilling.vue"),
      },

      {
        path: "bills/view",
        name: "ViewBills",
        component: () =>
          import("@/components/billing/view_bills/ViewBills.vue"),
      },

      {
        path: "businesses/renew",
        name: "RenewSbp",
        component: () =>
          import("@/components/businesses/renew_sbp/RenewSbp.vue"),
        props: true,
      },

      // {
      //   path: "statements/property-rate",
      //   name: routeObjects.propertyRateStatement.name,
      //   component: () =>
      //     import(
      //       "@/components/properties/property_statements/property_rate_statement/PropertyRateStatement.vue"
      //     ),
      // },

      {
        path: "properties/property-search",
        name: routeObjects.propertySearch.name,
        component: () =>
          import(
            "@/components/properties/property_statements/property_search/PropertySearch.vue"
          ),
      },

      {
        path: "pay",
        name: routeObjects.payment.name,
        props: true,
        component: () => import("@/components/payment/Payment.vue"),
      },

      {
        path: "logout",
        name: "Logout",
        component: () => import("@/components/auth/logout/Logout.vue"),
      },

      {
        path: "login-test",
        name: "login-test",
        component: () => import("@/components/auth/LoginTest.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
