<script setup>
import { computed, ref, onMounted } from "vue";
import BackButton from "@/components/utils/BackButton.vue";
import Table from "@/components/utils/Table.vue";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import { useSubcountyWard, countyIsKirinyaga } from "@/utils/vue_helpers";

const { subcounties, wards, towns, locationData, locationDataLoading } = useSubcountyWard();

const { router } = useRouterFromInstance();

const props = defineProps({
  draftUnderView: {
    type: Object,
    default: null,
  },

});

const emit = defineEmits(['goBackClicked'])

const building_plan_Details = ref({})
const architect_arr_object = ref([])
const client_arr_object = ref([])
const property_arr_object = ref([])
const structural_eng_arr_object = ref([])

const sub_county_name = ref(null);
const ward_name = ref(null);
const town_name = ref(null);

function returnSubCountyName(sub_county_id){
    console.log("returnSubCountyName")
    console.log(subcounties.value)

    if(sub_county_id != null){
        subcounties.value.forEach((item, index) => {
            if(item.id == sub_county_id){
                return item.sub_county_name

            }

        })

    }

}

function returnWardName(ward_id){

}

function returnTownName(town_id){

}

function returnTotalPlinthAreas(plinth_area) {
    if (plinth_area != null) {
        return plinth_area + ' Sq Meters'

    }

    let total_area = 0

    building_plan_Details.plinth_areas_array.forEach((item) => {
        total_area = total_area + Number(item)

    })

    return total_area + ' Sq Meters'

}

function returnNumberOfFloors(num_of_floors) {
    if (num_of_floors) {
        return num_of_floors

    }
    return 'N/A'

}

function continueApplication(){
  router.push({
      name: "createBuildingApp", //use name for router push
      params: {
          draft_item: props.draftUnderView
      }
  });

}

onMounted(() => {
  console.log('Props draftUnderView')
  console.log(props.draftUnderView)

  building_plan_Details.value = JSON.parse(props.draftUnderView.building_plan_Details)
  architect_arr_object.value = JSON.parse(props.draftUnderView.architect)
  client_arr_object.value = JSON.parse(props.draftUnderView.client)
  property_arr_object.value = JSON.parse(props.draftUnderView.linked_properties)
  structural_eng_arr_object.value = JSON.parse(props.draftUnderView.structural_eng)

  console.log("architect_arr_object.value")
  console.log(architect_arr_object.value)

  console.log("subcounties_12356")
  console.log(subcounties.value)

  console.log("wards_123456")
  console.log(wards.value)


//   props.draftUnderView.architect

//   props.draftUnderView.linked_properties

//   props.draftUnderView.client

//   props.draftUnderView.structural_eng



})


</script>

<template>
    <v-container fluid class="pa-0">

        <v-row>
            <v-col cols="12" md="1">
                <BackButton @click="$emit('goBackClicked')">
                    Back To Drafts
                </BackButton>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Building Plan Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <div>
                    <b>Parcel Number :</b>{{ building_plan_Details.parcel_no }}
                </div>

                <div>
                    <b>Sub County :</b>{{ returnSubCountyName(building_plan_Details.sub_county_id) }}
                </div>

                <div>
                    <b>Ward :</b>{{ returnWardName(building_plan_Details.ward_id) }}
                </div>

                <div>
                    <b>Town :</b>{{ returnTownName(building_plan_Details.town_name) }}
                </div>

                <div>
                    <b>Latitude :</b>{{ building_plan_Details.latitude }}
                </div>

                <div>
                    <b>Longitude :</b>{{ building_plan_Details.longitude }}
                </div>

            </v-col>

            <v-col cols="12" md="5">
                <div>
                    <b>Cost of Building :</b>{{ building_plan_Details.cost }}
                </div>

                <div>
                    <b>Unit of Measure :</b>{{ building_plan_Details.unit_of_measure }}
                </div>

                <div>
                    <b>Plinth Area :</b>{{ returnTotalPlinthAreas(building_plan_Details.plinth_area) }}
                </div>

                <div>
                    <b>Type of Development :</b>{{ building_plan_Details.type_of_development_name  }}
                </div>

                <div>
                    <b>Number of Floors :</b>{{ returnNumberOfFloors(building_plan_Details.number_of_floors) }}
                </div>

                <div>
                    <b>Physical Location/Landmark :</b>{{ building_plan_Details.physical_address }}
                </div>

            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Architect Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'Architect Name',
                                    value: 'customer_name',
                                    fn: (architectsData) => architectsData.first_name ? architectsData.first_name + ' ' + architectsData.last_name : architectsData.organisation_name

                                },

                                {
                                    text: 'Architect No',
                                    value: 'architect_number',

                                },

                                {
                                    text: 'Telephone No',
                                    value: 'phone_number',

                                },

                                {
                                    text: 'Email',
                                    value: 'email_address',

                                },

                            ]" :items="architect_arr_object"
                                emptyText="No Architects Found" headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Client Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'Customer Name',
                                    value: 'customer_name',

                                },

                                {
                                    text: 'Phone No',
                                    value: 'phone_number1',

                                },

                                {
                                    text: 'ID No',
                                    value: 'id_number',

                                },

                                {
                                    text: 'Client Type',
                                    value: 'client_type',


                                },

                            ]" :items="client_arr_object" emptyText="No Clients Found"
                                headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Structural Engineer Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'Structural Engineer Name',
                                    value: 'customer_name',
                                    fn: (structuralEngData) => structuralEngData.first_name ? structuralEngData.first_name + ' ' + structuralEngData.last_name : structuralEngData.organisation_name

                                },

                                {
                                    text: 'Engineer No',
                                    value: 'structural_engineer_number',

                                },

                                {
                                    text: 'Telephone No',
                                    value: 'phone_number',

                                },

                                {
                                    text: 'Email',
                                    value: 'email_address',

                                },

                            ]" :items="structural_eng_arr_object"
                                emptyText="No Structural Engineers Found" headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Property Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[

                                {
                                    text: 'Sub County',
                                    value: 'sub_county',
                                    fn: (item) => item.sub_county ? item.sub_county.sub_county_name : ''

                                },

                                {
                                    text: 'Ward',
                                    value: 'ward',
                                    fn: (item) => item.ward ? item.ward.ward_name : ''

                                },

                                {
                                    text: 'Town',
                                    value: 'town',
                                    fn: (item) => item.town ? item.town.town_name : ''

                                },


                                {
                                    text: 'PRN No',
                                    value: 'prn'

                                },

                                {
                                    text: 'LR Number',
                                    value: 'lr_no'

                                },


                                {
                                    text: 'Block / Plot Number',
                                    value: 'block_plot_no',
                                    fn: (property) => (property.block_no || property.plot_no) ? property.block_no + '/' + property.plot_no : ''

                                },

                                {
                                    text: 'Physical Location',
                                    value: 'physical_location'

                                },

                            ]" :items="property_arr_object"
                                emptyText="No Properties Found" headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row justify-md="end">
            <v-col cols="12" md="auto">
                <v-spacer></v-spacer>
                <v-btn depressed class="secondary text-none mt-3" @click="continueApplication()">
                    Continue With Draft
                    <v-icon right> mdi-content-save </v-icon>
                </v-btn>
            </v-col>
        </v-row>

    </v-container>

</template>

