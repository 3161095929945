<script setup>
import LabelledField from "@/components/utils/LabelledField.vue";
import { createBuildingAppData, fetchSewerTypes, fetchDevelopmentTypes, fetchPropertyAreaUnits, fetchPropertyOwnerships, snackBarInfoFunction, addBuildingDetailsToDraft } from "./buildings_business_logic.js";
import {
    fieldRequired,
    validateIfNotEmpty,
    emailValid,
    floatOnly,
    isNumber,
} from "@/utils/input_validation.js";
import PreviousButton from "@/components/utils/PreviousButton.vue";
import NextButton from "@/components/utils/NextButton.vue";

import { computed, ref, onMounted } from "vue";

const emit = defineEmits(['goToNextStep', 'goToPreviousStep'])

const formBuildingPlan = ref(null);
const formPlinthAreas = ref(null);

const { snackbar_info } = snackBarInfoFunction()

const { create_building_aplication_data, building_additional_data } = createBuildingAppData()

const property_ownerships_object = fetchPropertyOwnerships()
const property_ownerships = ref([])
const loadingButton = ref(false)

const type_of_application = [
        {
          id: "0",
          name: "New Application",
        },
        {
          id: "1",
          name: "Existing Application",
        },
      ]

const property_area_units_object = fetchPropertyAreaUnits()
const property_area_units = ref([])

const development_types_object = fetchDevelopmentTypes()
const development_types = ref([])

const sewer_types_object = fetchSewerTypes()
const sewer_types = ref([])

const enter_plinth_area_dialog = ref(false)

function verifyAndGoToPreviousStep() {
    emit('goToPreviousStep')

}

function submitPlinthAreas() {
    if(formPlinthAreas.value.validate()){
        enter_plinth_area_dialog.value = false

    }

}

async function verifyAndGoToNextStep() {

    if (!formBuildingPlan.value.validate()) {
        return
    }

    if (show_if_floors_required.value) {
        if (create_building_aplication_data.plinth_areas_array.length == 0) {
            snackbar_info.snackbar_message = "Please add the plinth areas, click the 'Enter Plinth Areas' button."
            snackbar_info.snackbar = true
            snackbar_info.snackbar_timeout = 3000
            return

        }

    }


    let prop_unit = property_area_units.value.find(element => element.id == create_building_aplication_data.unit_of_measure_id);
    let dev_type = development_types.value.find(element => element.id == create_building_aplication_data.type_of_development_id);
    let property_ownership = property_ownerships.value.find(element => element.id == create_building_aplication_data.land_tenure_id);

    if (prop_unit) {
        building_additional_data.unit_of_measure = prop_unit.unit_name

    }

    if (dev_type) {
        building_additional_data.type_of_development_name = dev_type.type_of_development

    }

    if(property_ownership){
        building_additional_data.property_ownership = property_ownership.ownership

    }

    loadingButton.value = true
  
    const add_building_application = await addBuildingDetailsToDraft()

    if(add_building_application.status == "success"){
        emit('goToNextStep')

    }
    else{
        snackBarErrorMessage("Error occured Please contact admin")
    
    }

  loadingButton.value = false

}

function openPlinthAreasDialog() {

    create_building_aplication_data.plinth_areas_array.length = 0

    if (show_if_floors_required.value) {
        if (create_building_aplication_data.number_of_floors == null || create_building_aplication_data.number_of_floors == '') {
            alert("Pleave provide input for the Number of floors")
            return

        }

        if (!Number.isInteger(parseInt(create_building_aplication_data.number_of_floors))) {
            alert("Please enter a number for the, Number of Floors")
            return
        }


    }

    enter_plinth_area_dialog.value = true

}

function returnFloorNumber(item) {
    return "Floor " + item

}

function snackBarErrorMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "error"
    snackbar_info.snackbar = true

}

function snackBarSuccessMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "success"
    snackbar_info.snackbar = true

}


const show_if_floors_required = computed(() => {
    console.log("Inside Computed")
    let my_id = create_building_aplication_data.type_of_development_id

    let dev_type = development_types.value.find(element => element.id == my_id);

    if (dev_type?.calculation_technique?.calculation_type == "Based on the Number of Floors") {
        return true

    }

    return false

});

onMounted(() => {
    sewer_types_object.mutate(
        {},
        {
            onSuccess(sewer_types_object) {
                sewer_types.value = sewer_types_object

            },
        }
    );

    development_types_object.mutate(
        {},
        {
            onSuccess(development_types_object) {
                development_types.value = development_types_object

            },
        }
    );

    property_area_units_object.mutate(
        {},
        {
            onSuccess(property_area_units_object) {
                property_area_units.value = property_area_units_object

            },
        }
    );

    property_ownerships_object.mutate(
        {},
        {
            onSuccess(property_ownerships_object) {
                property_ownerships.value = property_ownerships_object

            },
        }
    );




});

</script>


<template>
    <v-container fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-form ref="formBuildingPlan" lazy-validation>

            <v-row>
                <v-col cols="12" md="10">
                    <LabelledField label="Please Select Type of Application" required>
                        <v-select :items="type_of_application" item-text="name" item-value="name"
                            outlined dense class="text-field-background" required
                            v-model="create_building_aplication_data.type_of_application" :rules="[fieldRequired]"
                            hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>
                
            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Subcounty" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="building_additional_data.sub_county_name" :readonly="true" :rules="[fieldRequired]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Ward" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="building_additional_data.ward_name" :readonly="true" :rules="[fieldRequired]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Town" hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="building_additional_data.town_name" :readonly="true" :rules="[]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Parcel Number" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="create_building_aplication_data.parcel_no" :rules="[fieldRequired]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Area of Property(Hectares)" required hint="">
                        <v-text-field class="text-field-background" outlined dense
                            v-model="create_building_aplication_data.area_of_property" :rules="[fieldRequired]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Cost of Building(Kshs)" required hint="">
                        <v-text-field class="text-field-background" outlined dense
                            v-model="create_building_aplication_data.cost" :rules="[fieldRequired, floatOnly]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Latitude" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="create_building_aplication_data.latitude" :rules="[fieldRequired, floatOnly]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Longitude" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="create_building_aplication_data.longitude" :rules="[fieldRequired, floatOnly]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Unit of Measure">
                        <v-select :items="property_area_units" item-text="unit_name" item-value="id" :readonly="true"
                            outlined dense class="text-field-background" required
                            v-model="create_building_aplication_data.unit_of_measure_id" :rules="[fieldRequired]"
                            hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Land Tenure" required>
                        <v-select :items="property_ownerships" item-text="ownership" item-value="id" outlined dense
                            class="text-field-background" v-model="create_building_aplication_data.land_tenure_id"
                            :rules="[fieldRequired]" :readonly="true" hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Development Type">
                        <v-select :items="development_types" item-text="type_of_development" item-value="id" outlined
                            dense class="text-field-background" required
                            v-model="create_building_aplication_data.type_of_development_id" :rules="[fieldRequired]"
                            hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Physical Location/Landmark" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="create_building_aplication_data.physical_address" :rules="[fieldRequired]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>
            </v-row>

            <v-row>
                <v-col v-if="!show_if_floors_required" cols="12" md="5">
                    <LabelledField label="Plinth Area(In Sq Meters)" required hint="">
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="create_building_aplication_data.plinth_area" :rules="[fieldRequired]"
                            hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="5">
                    <LabelledField label="Sewer" required>
                        <v-select :items="sewer_types" item-text="sewer_type" item-value="id" outlined dense
                            class="text-field-background" v-model="create_building_aplication_data.sewer"
                            :rules="[fieldRequired]" hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col v-if="show_if_floors_required" cols="12" md="5">
                    <LabelledField label="Number of Floors" required>
                        <v-text-field class="text-field-background" outlined dense required
                            v-model="create_building_aplication_data.number_of_floors"
                            :rules="[fieldRequired, isNumber]" hide-details="auto"></v-text-field>
                    </LabelledField>
                </v-col>

                <v-col v-if="show_if_floors_required" cols="12" md="5">
                    <LabelledField label="Enter Plinth Areas" required>
                        <v-btn depressed class="rounded-lg text-none" color="success"
                            @click="openPlinthAreasDialog()">Enter
                            Plinth Areas
                        </v-btn>
                    </LabelledField>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <h2>Perimeter Wall Available ?</h2>
                    <LabelledField>
                        <v-checkbox label=""
                            v-model="create_building_aplication_data.perimeter_wall_available"></v-checkbox>
                    </LabelledField>
                </v-col>

            </v-row>

            <v-expand-transition>
                <v-row v-if="create_building_aplication_data.perimeter_wall_available">
                    <v-col cols="12" md="5">
                        <LabelledField label="Perimeter Wall Length (Linear Meters)" required hint="">
                            <v-text-field class="text-field-background" outlined dense required
                                v-model="create_building_aplication_data.perimeter_wall_length"
                                :rules="[fieldRequired, floatOnly]" hide-details="auto"></v-text-field>
                        </LabelledField>
                    </v-col>

                </v-row>

            </v-expand-transition>


        </v-form>


        <v-row justify="center" justify-md="end">
            <v-col>
                <PreviousButton @click="verifyAndGoToPreviousStep" />

            </v-col>

            <v-col>
                <NextButton @click="verifyAndGoToNextStep()" :loading="loadingButton">
                    <template #loadingText> Please Wait </template>
                </NextButton>

            </v-col>
        </v-row>

        <!-- Enter Plinth Areas Dialog -->
        <v-row justify="center">
            <v-dialog v-model="enter_plinth_area_dialog" width="1024">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Please enter the plinth area for each floor (Per Square Meter)
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="enter_plinth_area_dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-container>

                            <v-form ref="formPlinthAreas" lazy-validation>

                                <v-row
                                    v-for="(item, index) in parseInt(create_building_aplication_data.number_of_floors ?? 0)"
                                    :key="item">

                                    <v-col>
                                        <LabelledField :label="returnFloorNumber(item)" required hint="">
                                            <v-text-field class="text-field-background" outlined dense required
                                                v-model="create_building_aplication_data.plinth_areas_array[index]"
                                                :rules="[fieldRequired, floatOnly]" hide-details="auto"></v-text-field>
                                        </LabelledField>
                                    </v-col>

                                </v-row>

                            </v-form>

                        </v-container>
                        <small>
                            <span class="red--text"> *</span> Indicates required
                            field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red lighten-2 ml-1" class="white--text" @click="enter_plinth_area_dialog = false">
                            Cancel
                        </v-btn>
                        <v-btn color="success" class="white--text mr-1" @click="submitPlinthAreas">
                            Submit Plinth Areas
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- Enter Plinth Areas Dialog   -->

    </v-container>

</template>