<script setup>
import { computed, watch, ref, onMounted } from "vue";
import LoadingArea from "@/components/utils/LoadingArea.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import Table from "@/components/utils/Table.vue";
import { useSearchForClients, useSearchForArchitects, useSearchForStructuralEngineers, createBuildingAppData, snackBarInfoFunction, addClientFunction, addArchitectFunction, addStrucEngFunction } from "./buildings_business_logic";
import ActionButton from "@/components/utils/ActionButton.vue";
import { actions } from "@/utils/constants";
import PreviousButton from "@/components/utils/PreviousButton.vue";
import NextButton from "@/components/utils/NextButton.vue";
import store from "@/store/index";
import {
    GET_USER_DATA,
} from "@/store/getters/user";

const { clientsData, searchModel_clients } = useSearchForClients();
const { architectsData, searchModel_architects } = useSearchForArchitects();
const { structuralEngData, searchModel_strucEngs } = useSearchForStructuralEngineers();
let stepper_position = ref(1)
let role_found = ref(false)
const { snackbar_info } = snackBarInfoFunction()
const { create_building_aplication_data, building_additional_data } = createBuildingAppData()

const emit = defineEmits(['goToNextStep', 'goToPreviousStep'])

const loadingButton = ref(false)

function verifyAndGoToPreviousStep() {
    emit('goToPreviousStep')

}

function verifyAndGoToNextStep() {

    if (create_building_aplication_data.clients_array_data.length == 0) {
        snackbar_info.snackbar_message = "Please attach a Client."
        snackbar_info.snackbar = true
        snackbar_info.snackbar_timeout = 3000

        stepper_position.value = 1
        return

    }

    if (create_building_aplication_data.architects_array_data.length == 0) {
        2
        snackbar_info.snackbar_message = "Please attach an Architect."
        snackbar_info.snackbar = true
        snackbar_info.snackbar_timeout = 3000

        stepper_position.value = 2
        return

    }


    emit('goToNextStep')

}

function snackBarErrorMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "error"
    snackbar_info.snackbar = true

}

function snackBarSuccessMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "success"
    snackbar_info.snackbar = true

}

function attachClient(client) {
    if (create_building_aplication_data.clients_array_data.length > 0) {
        snackbar_info.snackbar_message = "Only 1 Client can be added at a time - Remove selected client to add another."
        snackbar_info.snackbar = true
        snackbar_info.snackbar_timeout = 5000

    }
    else {
        create_building_aplication_data.clients_array_data.push(client)

    }

}

function onRemoveClientFromApplication() {
    create_building_aplication_data.clients_array_data = []

}

function attachArchitect(architect) {
    if (create_building_aplication_data.architects_array_data.length > 0) {
        snackbar_info.snackbar_message = "Only 1 Architect can be added at a time - Remove selected Architect to add another."
        snackbar_info.snackbar = true
        snackbar_info.snackbar_timeout = 5000

    }
    else {
        create_building_aplication_data.architects_array_data.push(architect)

    }

}

function onRemoveArchitectFromApplication() {
    create_building_aplication_data.architects_array_data = []

}

function attachStructuralEng(struc_eng) {
    if (create_building_aplication_data.structural_eng_array_data.length > 0) {
        snackbar_info.snackbar_message = "Only 1 Structural Eng can be added at a time - Remove selected Engineer to add another."
        snackbar_info.snackbar = true
        snackbar_info.snackbar_timeout = 5000

    }
    else {
        create_building_aplication_data.structural_eng_array_data.push(struc_eng)

    }

}

function onRemoveStructuralEngFromApplication() {
    create_building_aplication_data.structural_eng_array_data = []

}

async function changeStepA() {
    //Client Step
    loadingButton.value = true

    const add_client_function = await addClientFunction()

    if (add_client_function.status == "success") {
        store.getters[GET_USER_DATA].profile.role_name == 'County Architect'

        if (store.getters[GET_USER_DATA].profile.role_name == 'County Architect') {
            stepper_position.value = 3

        }
        else if (store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer') {
            stepper_position.value = 2

        }

    }
    else {
        snackBarErrorMessage("Error occured Please contact admin")

    }

    loadingButton.value = false

}

function prevStepA() {
    verifyAndGoToPreviousStep()

}

async function changeStepB() {
    //Architect Step
    loadingButton.value = true

    const add_arch_function = await addArchitectFunction()

    if (add_arch_function.status == "success") {
        stepper_position.value = 3

    }
    else {
        snackBarErrorMessage("Error occured Please contact admin")

    }

    loadingButton.value = false

}

function prevStepB() {
    console.log("Prev_Step")
    stepper_position.value = 1

}

async function changeStepC() {
    //Struc Eng Step
    loadingButton.value = true

    const add_struc_eng_function = await addStrucEngFunction()

    if (add_struc_eng_function.status == "success") {
        verifyAndGoToNextStep()

    }
    else {
        snackBarErrorMessage("Error occured Please contact admin")

    }

    loadingButton.value = false

}

function prevStepC() {
    stepper_position.value = 2

}

onMounted(() => {
    ////store.getters[GET_USER_DATA].profile.role_name == 'County Architect'

    if (store.getters[GET_USER_DATA].profile.role_name == 'County Architect') {
        if (create_building_aplication_data.architects_array_data.length == 0) {
            create_building_aplication_data.architects_array_data.push(store.getters[GET_USER_DATA].architect_object)
            role_found.value = true

        }

    }
    else if (store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer') {
        if (create_building_aplication_data.structural_eng_array_data.length == 0) {
            create_building_aplication_data.structural_eng_array_data.push(store.getters[GET_USER_DATA].struc_eng_object)
            role_found.value = true

        }

    }


})



</script>

<template>
    <v-container fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-stepper v-model="stepper_position" vertical class="elevation-0">
            <v-stepper-step :complete="stepper_position > 1" step="1">
                Add Client <span class="error--text">*</span>
            </v-stepper-step>

            <v-stepper-content step="1">

                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 text-center font-weight-bold primary--text">
                            Clients
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <div class="text-h5 text-center font-weight-bold">
                            Searching Criteria
                        </div>

                        <div style="width: 40%; margin: auto;">
                            <v-list color="transparent">
                                <v-list-item>
                                    <v-list-item-title>
                                        <div>I) Enter <b>ID No</b> or</div>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title>
                                        <div>II) Enter <b>Phone No(Format: 254714345...)</b></div>
                                    </v-list-item-title>
                                </v-list-item>

                            </v-list>

                        </div>

                    </v-col>

                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <LabelledField label="Search">
                            <v-text-field class="background text-field" outlined dense v-model="searchModel_clients"
                                clearable />
                        </LabelledField>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">

                        <LoadingArea :loading="clientsData.fetchingData">

                            <v-container fluid class="pa-0">
                                <v-row>
                                    <v-col cols="12">

                                        <Table :headers="[
                                            {
                                                text: 'Customer Name',
                                                value: 'customer_name',

                                            },

                                            {
                                                text: 'Phone No',
                                                value: 'phone_number1',

                                            },

                                            {
                                                text: 'ID No',
                                                value: 'id_number',

                                            },

                                            {
                                                text: 'Client Type',
                                                value: 'client_type',


                                            },

                                            { text: 'Add Client', value: 'add_building', action: true },

                                        ]" :items="clientsData.fetchedData" :loading="clientsData.fetchingData"
                                            emptyText="No Clients Found" headClasses="primary white--text">

                                            <template #item.add_building="{ item: client }">
                                                <ActionButton :action="actions.addClient" inTable
                                                    @click="attachClient(client)" />
                                            </template>

                                        </Table>

                                    </v-col>
                                </v-row>
                            </v-container>

                        </LoadingArea>

                    </v-col>
                </v-row>


                <v-expand-transition>
                    <v-row v-if="create_building_aplication_data.clients_array_data.length">
                        <v-col cols="12">

                            <v-container fluid class="pa-0">
                                <v-row>
                                    <v-col cols="12">
                                        <h2>Attached Client</h2>
                                    </v-col>

                                    <v-col cols="12">

                                        <Table :headers="[
                                            {
                                                text: 'Customer Name',
                                                value: 'customer_name',

                                            },

                                            {
                                                text: 'Phone No',
                                                value: 'phone_number1',

                                            },

                                            {
                                                text: 'ID No',
                                                value: 'id_number',

                                            },

                                            {
                                                text: 'Client Type',
                                                value: 'client_type',


                                            },

                                            { text: 'Remove Building', value: 'remove_building', action: true },

                                        ]" :items="create_building_aplication_data.clients_array_data"
                                            emptyText="No Clients Found" headClasses="primary white--text">

                                            <template #item.remove_building="{ item: client }">
                                                <ActionButton :action="actions.removeClient" inTable
                                                    @click="onRemoveClientFromApplication()" />
                                            </template>

                                        </Table>

                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-col>
                    </v-row>

                </v-expand-transition>


                <v-btn color="primary" @click="changeStepA" :loading="loadingButton">
                    Continue
                </v-btn>
                <v-btn text @click="prevStepA">
                    Previous
                </v-btn>
            </v-stepper-content>

            <template v-if="!(store.getters[GET_USER_DATA].profile.role_name == 'County Architect')">
            <!-- Add an and to also check if the architect array has an architect -->

                <v-stepper-step :complete="stepper_position > 2" step="2">
                    Add Architect <span class="error--text">*</span>
                </v-stepper-step>

                <v-stepper-content step="2">

                    <v-row>
                        <v-col cols="12">
                            <div class="text-h6 text-center font-weight-bold primary--text">
                                Architects
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-h5 text-center font-weight-bold">
                                Searching Criteria
                            </div>

                            <div style="width: 40%; margin: auto;">
                                <v-list color="transparent">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <div>I) Enter <b>Architect No</b> or</div>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-title>
                                            <div>II) Enter <b>Telephone No</b> or</div>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-title>
                                            <div>II) Enter <b>Email (Email registered with)</b></div>
                                        </v-list-item-title>
                                    </v-list-item>

                                </v-list>

                            </div>

                        </v-col>

                    </v-row>

                    <v-row justify="center">
                        <v-col cols="12" md="6">
                            <LabelledField label="Search">
                                <v-text-field class="background text-field" outlined dense
                                    v-model="searchModel_architects" clearable />
                            </LabelledField>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">

                            <LoadingArea :loading="architectsData.fetchingData">

                                <v-container fluid class="pa-0">
                                    <v-row>
                                        <v-col cols="12">

                                            <Table :headers="[
                                                {
                                                    text: 'Architect Name',
                                                    value: 'customer_name',
                                                    fn: (architectsData) => architectsData.first_name ? architectsData.first_name + ' ' + architectsData.last_name : architectsData.organisation_name

                                                },

                                                {
                                                    text: 'Architect No',
                                                    value: 'architect_number',

                                                },

                                                {
                                                    text: 'Telephone No',
                                                    value: 'phone_number',

                                                },

                                                {
                                                    text: 'Email',
                                                    value: 'email_address',


                                                },

                                                { text: 'Add Architect', value: 'add_architect', action: true },

                                            ]" :items="architectsData.fetchedData"
                                                :loading="architectsData.fetchingData" emptyText="No Architects Found"
                                                headClasses="primary white--text">

                                                <template #item.add_architect="{ item: architect }">
                                                    <ActionButton :action="actions.addClient" text="Add Architect"
                                                        inTable @click="attachArchitect(architect)" />
                                                </template>

                                            </Table>

                                        </v-col>
                                    </v-row>
                                </v-container>

                            </LoadingArea>

                        </v-col>
                    </v-row>

                    <v-expand-transition>
                        <v-row v-if="create_building_aplication_data.architects_array_data.length">
                            <v-col cols="12">

                                <v-container fluid class="pa-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2>Attached Architect</h2>
                                        </v-col>

                                        <v-col cols="12">

                                            <Table :headers="[
                                                {
                                                    text: 'Architect Name',
                                                    value: 'customer_name',
                                                    fn: (architectsData) => architectsData.first_name ? architectsData.first_name + ' ' + architectsData.last_name : architectsData.organisation_name

                                                },

                                                {
                                                    text: 'Architect No',
                                                    value: 'architect_number',

                                                },

                                                {
                                                    text: 'Telephone No',
                                                    value: 'phone_number',

                                                },

                                                {
                                                    text: 'Email',
                                                    value: 'email_address',

                                                },

                                                { text: 'Remove Architect', value: 'remove_architect', action: true },

                                            ]" :items="create_building_aplication_data.architects_array_data"
                                                emptyText="No Architects Found" headClasses="primary white--text">

                                                <template #item.remove_architect="{ item: architect }">
                                                    <ActionButton :action="actions.removeClient" text="Remove Architect"
                                                        inTable @click="onRemoveArchitectFromApplication()" />
                                                </template>

                                            </Table>

                                        </v-col>
                                    </v-row>
                                </v-container>

                            </v-col>
                        </v-row>

                    </v-expand-transition>

                    <v-btn color="primary" @click="changeStepB" :loading="loadingButton">
                        Continue
                    </v-btn>
                    <v-btn text @click="prevStepB">
                        Previous
                    </v-btn>
                </v-stepper-content>

            </template>

            <template v-if="!(store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer')">

                <v-stepper-step :complete="stepper_position > 3" :step="role_found ? '2' : '3'">
                    Add Structural Engineer(Optional)
                </v-stepper-step>

                <v-stepper-content step="3">

                    <v-row>
                        <v-col cols="12">
                            <div class="text-h6 text-center font-weight-bold primary--text">
                                Structural Engineers
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-h5 text-center font-weight-bold">
                                Searching Criteria
                            </div>

                            <div style="width: 40%; margin: auto;">
                                <v-list color="transparent">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <div>I) Enter <b>Engineer No</b> or</div>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-title>
                                            <div>II) Enter <b>Telephone No</b> or</div>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-title>
                                            <div>II) Enter <b>Email (Email registered with)</b></div>
                                        </v-list-item-title>
                                    </v-list-item>

                                </v-list>

                            </div>

                        </v-col>

                    </v-row>

                    <v-row justify="center">
                        <v-col cols="12" md="6">
                            <LabelledField label="Search">
                                <v-text-field class="background text-field" outlined dense
                                    v-model="searchModel_strucEngs" clearable />
                            </LabelledField>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">

                            <LoadingArea :loading="structuralEngData.fetchingData">

                                <v-container fluid class="pa-0">
                                    <v-row>
                                        <v-col cols="12">

                                            <Table :headers="[
                                                {
                                                    text: 'Structural Engineer Name',
                                                    value: 'customer_name',
                                                    fn: (structuralEngData) => structuralEngData.first_name ? structuralEngData.first_name + ' ' + structuralEngData.last_name : structuralEngData.organisation_name

                                                },

                                                {
                                                    text: 'Engineer No',
                                                    value: 'structural_engineer_number',

                                                },

                                                {
                                                    text: 'Telephone No',
                                                    value: 'phone_number',

                                                },

                                                {
                                                    text: 'Email',
                                                    value: 'email_address',

                                                },

                                                { text: 'Add Struc Eng', value: 'add_struc_eng', action: true },

                                            ]" :items="structuralEngData.fetchedData"
                                                :loading="structuralEngData.fetchingData"
                                                emptyText="No Structural Engineers Found"
                                                headClasses="primary white--text">

                                                <template #item.add_struc_eng="{ item: structural_eng }">
                                                    <ActionButton :action="actions.addClient" text="Add Struc Eng"
                                                        inTable @click="attachStructuralEng(structural_eng)" />
                                                </template>

                                            </Table>

                                        </v-col>
                                    </v-row>
                                </v-container>

                            </LoadingArea>

                        </v-col>
                    </v-row>

                    <v-expand-transition>
                        <v-row v-if="create_building_aplication_data.structural_eng_array_data.length">
                            <v-col cols="12">

                                <v-container fluid class="pa-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2>Attached Structural Engineer</h2>
                                        </v-col>

                                        <v-col cols="12">

                                            <Table :headers="[
                                                {
                                                    text: 'Structural Engineer Name',
                                                    value: 'customer_name',
                                                    fn: (structuralEngData) => structuralEngData.first_name ? structuralEngData.first_name + ' ' + structuralEngData.last_name : structuralEngData.organisation_name

                                                },

                                                {
                                                    text: 'Engineer No',
                                                    value: 'structural_engineer_number',

                                                },

                                                {
                                                    text: 'Telephone No',
                                                    value: 'phone_number',

                                                },

                                                {
                                                    text: 'Email',
                                                    value: 'email_address',


                                                },

                                                { text: 'Remove Struc Eng', value: 'remove_struc_eng', action: true },

                                            ]" :items="create_building_aplication_data.structural_eng_array_data"
                                                emptyText="No Struc Eng Found" headClasses="primary white--text">

                                                <template #item.remove_struc_eng="{ item: struc_eng }">
                                                    <ActionButton :action="actions.removeClient" text="Remove Struc Eng"
                                                        inTable @click="onRemoveStructuralEngFromApplication()" />
                                                </template>

                                            </Table>

                                        </v-col>
                                    </v-row>
                                </v-container>

                            </v-col>
                        </v-row>

                    </v-expand-transition>

                    <v-btn color="primary" @click="changeStepC" :loading="loadingButton">
                        Continue
                    </v-btn>
                    <v-btn text @click="prevStepC">
                        Previous
                    </v-btn>
                </v-stepper-content>

            </template>

        </v-stepper>

        <v-row class="mt-2" justify="center" justify-md="end">
            <v-col>
                <PreviousButton @click="verifyAndGoToPreviousStep" />

            </v-col>

            <v-col>
                <NextButton @click="verifyAndGoToNextStep">
                    <template #loadingText> Please Wait </template>
                </NextButton>

            </v-col>
        </v-row>

    </v-container>


</template>