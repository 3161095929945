<script setup>
import Table from "@/components/utils/Table.vue";
import {
  getFormattedMoney,
  getFormattedBillDate,
  getPaymode,
  arrayNotEmpty,
} from "@/utils/functions.js";
import { actions } from "@/utils/constants";
import ActionButton from "@/components/utils/ActionButton.vue";
import { onMounted } from "vue"
import { useDownloadFile } from "@/utils/vue_helpers";

const { downloadFile } = useDownloadFile();

const props = defineProps({
  buildings: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["viewDraftItem", "continueApplication"]);


onMounted(() => {
  console.log("onMounted Table")
  console.log(props.buildings)


})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <div :style="{ position: 'relative' }">
          <Table :headers="[
            {
              text: 'Plinth Area',
              value: 'plinth_area',

            },

            {
              text: 'Physical Location/Landmark',
              value: 'physical_address',

            },

            {
              text: 'Parcel Number',
              value: 'parcel_number',

            },

            {
              text: 'Client Name',
              value: 'client_name',

            },

            {
              text: 'Architect Name',
              value: 'architect_name',
              
            },

            {
              text: 'Created At',
              value: 'created_at',
              fn: (house) => getFormattedBillDate(house.created_at)

            },


            {
              text: 'Actions',
              value: 'view',
              action: true,
              fn: (house) => house.workflow_status.status_name
            },


            // { text: 'Pay', value: 'is_renewal', action: true },
          ]" :items="buildings" :loading="loading" emptyText="No Buildings Found" headClasses="primary white--text">

            <template #item.view="{ item }">
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 primary--text" @click="$emit('viewDraftItem', item)" v-bind="attrs"
                      v-on="on">

                      <v-icon medium>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("viewMsg") }}</span>
                </v-tooltip>
              </div>

              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 primary--text" @click="$emit('continueApplication', item)" v-bind="attrs"
                      v-on="on">

                      <v-icon medium>mdi-page-next</v-icon>
                    </v-btn>
                  </template>
                  <span>Continue with Draft</span>
                </v-tooltip>
              </div>

            </template>
            
            <template #item.getBill="{ item: house }">
              <ActionButton :action="actions.pay" inTable @click="$emit('payForHouse', house)" />
            </template>

            <template #item.getReceipt="{ item: house }">
              <ActionButton :action="actions.pay" inTable @click="$emit('payForHouse', house)" />
            </template>
          </Table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
