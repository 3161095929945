<script setup>
import { useViewBuildings, useGenerateHouseBill } from "./buildings_business_logic";
import ViewBuildingsTable from "@/components/buildings/tables/ViewBuildingsTable.vue";
import ViewBuilding from "./ViewBuilding.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref, onMounted } from "vue";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import Pagination from "../utils/Pagination.vue";
import LoadingArea from "../utils/LoadingArea.vue";
import { routes } from "@/router/routes";
import PayForHouse from "./PayForHouse.vue";
import Instructions from "./Instructions.vue";
import Table from "@/components/utils/Table.vue";

resetSnackbarOnMount();

const { router } = useRouterFromInstance();

const { buildingsData, searchModel } = useViewBuildings();

const generateHouseBill = useGenerateHouseBill();

const pages = {
  VIEW_BUILDINGS_DETAILS: 0,
  VIEW_HOUSE: 1,
  VIEW_MAKE_BUILDING_APPLICATION: 2,
  VIEW_VEHICLE_DETAILS: 3,
  VIEW_BUILDINGS_INSTRUCTION_DETAILS: 4,
};

const currentPage = ref(pages.VIEW_BUILDINGS_DETAILS); //ref(pages.VIEW_BUILDINGS_DETAILS)

const buildingUnderView = ref(null);
const houseUnderViewNotEmpty = computed(() => {
  return buildingUnderView.value !== null;
});

function createBuildingApplication(){
  currentPage.value = pages.VIEW_BUILDINGS_INSTRUCTION_DETAILS;
  
}

function moveToViewMakeBuildingApplication(){
  currentPage.value = pages.VIEW_MAKE_BUILDING_APPLICATION;

}

function moveToViewBuildingPage(building) {
  buildingUnderView.value = building;
  currentPage.value = pages.VIEW_HOUSE;
}

function moveToViewHouseTable() {
  currentPage.value = pages.VIEW_BUILDINGS_DETAILS;
}

function moveToViewBuildings(){
  currentPage.value = pages.VIEW_BUILDINGS_DETAILS

}

function onViewPayClicked(house) {
  buildingUnderView.value = house;
  currentPage.value = pages.VIEW_MAKE_BUILDING_APPLICATION;
}

async function onPayHouseClicked({ house, amountToPay }) {
  generateHouseBill.mutate(
    {
      // rent_arrears: house.housetenants.rent_arrears,
      // monthly_rent: house.housetenants.monthly_rent,
      // current_balance: house.housetenants.current_balance,
      // amount_to_pay: amountToPay,
      // house_no: house.house_no,
      // sub_county_id: house.sub_county_id,
      desired_amount: amountToPay,
      house_stall_id: house.id,
    },
    {
      onSuccess(bill) {
        snackbarData.text = "House Bill Generated";
        snackbarData.color = "success";
        snackbarData.open = true;

        const paymentRoute = routes.payment.withQuery({
          billNumbersToPayAsString: bill.bill_number,
          directPayment: true,
          billsToPay: [bill],
        });
        router.push(paymentRoute);
      },
    }
  );
}

async function onPaginationClicked(newPage) {
  buildingsData.paginationData.currentPage = newPage;
}

onMounted(() => {
  console.log('Inside OnMounted')
  console.log(buildingsData)

  console.log('CurrentPage Value')
  console.log(currentPage.value)
 

})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless continuous>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Buildings
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" dense>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field class="background text-field" outlined dense v-model="searchModel" clearable />
            </LabelledField>
          </v-col>
        </v-row>
        <LoadingArea :loading="buildingsData.fetchingData" class="mt-4">

          <v-row>
            <v-spacer />
            <v-col cols="12" md="auto">
              <v-btn class="text-none" color="success" depressed @click="createBuildingApplication()">
                Create Building Application <v-icon right>mdi-office-building</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ViewBuildingsTable :buildings="buildingsData.fetchedData" :loading="buildingsData.fetchingData"
                @viewBuilding="moveToViewBuildingPage" @payForHouse="onViewPayClicked" />
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <Pagination :current-page="buildingsData.paginationData.currentPage"
                :total-number-of-items="buildingsData.paginationData.total"
                :items-per-page="buildingsData.paginationData.perPage" @paginationClicked="buildingsData.setNewPage($event)">
              </Pagination>
            </v-col>
          </v-row>
        </LoadingArea>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <ViewBuilding :building_application="buildingUnderView" showGoBack @goBackClicked="moveToViewHouseTable" />

            <!-- <ViewHouses :house="buildings" showGoBack @goBackClicked="moveToViewHouseTable" />  -->
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_BUILDINGS_INSTRUCTION_DETAILS">
        <v-row>
          <v-col cols="12">
            <Instructions @goBackClicked="moveToViewBuildings" @goNextSection="moveToViewMakeBuildingApplication">
              
            </Instructions>

          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_MAKE_BUILDING_APPLICATION">
        <v-row>
          <v-col cols="12">
            <PayForHouse :house="buildingUnderView" :loading="generateHouseBill.isLoading.value"
              @goBackClicked="moveToViewHouseTable" @payHouseClicked="onPayHouseClicked"></PayForHouse>
          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>
